export default [
  {
    key: 'payoutTwoDigit',
    label: 'field.payoutTwoDigit',
    rules: 'required|integer',
    type: 'currency',
  },
  {
    key: 'payoutThreeDigit',
    label: 'field.payoutThreeDigit',
    rules: 'required|integer',
    type: 'currency',
  },
]
