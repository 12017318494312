<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <b-card>
      <b-row class="betting-form-container">
        <b-col lg="12">
          <b-form-group :label="$t('field.lotteryName')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryId"
              name="lottery"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="lottery.id"
                v-for="(lottery, index) in lotteries"
                :key="`lottery-${index}-${lottery.id}`"
                buttons
              >
                <span>
                  {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <n-input
            :key="`form-${key}`"
            :fields="fields"
            v-model="data"
            :initValue="initData"
            class="data-form"
          >
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                v-if="$can('update', 'bet-setting')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NSingleSelect from "@/components/NSingleSelect";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import BetSettingMenu from "@/views/betSetting/BetSettingMenu.vue";

const LotteryRepository = Repository.get("lottery");
const LotteryPayoutRespository = Repository.get("lotteryPayout");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BFormRadio,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
    BetSettingMenu,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      lotteries: [],
      key: 1,
      data: {
        lotteryId: null,
        payoutThreeDigit: 0,
        payoutTwoDigit: 0,
      },
      initData: {},
      loading: false,
    };
  },
  watch: {
    "data.lotteryId"(value) {
      if (value) {
        this.key++;
        this.show();
      }
    },
  },
  mounted() {
    this.getLottery();
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
          if (!this.data.lotteryId && this.lotteries.length) {
            this.data.lotteryId = this.lotteries[0].id;
          }
        }
      });
    },
    show() {
      if (!this.data.lotteryId) {
        return;
      }
      this.loading = true;
      LotteryPayoutRespository.show(this.data.lotteryId)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          LotteryPayoutRespository.update(this.data.lotteryId, this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      return value;
    });

    return { fields };
  },
};
</script>